<template>
  <div>
    <!-- <ma-navigation-bar
      v-if="isLoggedIn"
      :userName="userName"
      :userInitials="initials"
      @user-logout="logout"
    ></ma-navigation-bar> -->
    <section>
      <side-navigation
        v-if="isLoggedIn"
        :userName="userName"
        :userInitials="initials"
        @user-logout="logout"
      >
        <slot></slot>
      </side-navigation>
    </section>
  </div>
</template>

<script>
import SideNavigation from "@/components/ui/MA/sideNavManager.vue";
// import MaNavigationBar from "@/components/ui/MA/MAnavigationBar.vue";
export default {
  name: "maContainer",
  components: {
    SideNavigation,
    // MaNavigationBar,
  },
  methods: {
    async logout() {
      const tokenPayload = localStorage.getItem("token");
      try {
        await this.$store.dispatch("auth/logout", tokenPayload);
        this.$router.push("/");
      } catch (err) {
        this.error = err.message || "Something went wrong, try later.";
      }
    },
  },
  mounted() {
    this.$store.commit(
      "profile/setSideBarPosition",
      JSON.parse(localStorage.getItem("sidebarOpen"))
    );
    this.$store.commit(
      "MAgeneralModule/setPracticeID",
      localStorage.getItem("practice")
    );
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters["auth/isAuthenticated"];
    },
    userName() {
      return this.$store.getters["auth/userName"];
    },
    initials() {
      return localStorage.getItem("initials");
    },
  },
};
</script>
